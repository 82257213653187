

































  import { Component, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'

  const cms = namespace('cms')
  const layout = namespace('layout')
  const cart = namespace('cart')
  const wishlist = namespace('wishlist')

  @Component({
    components: {
      OneCmsGridRender: () => import('~/components/molecules/renders/OneCmsGridRender.vue'),
    },
  })
  export default class OneFooter extends Vue {
    @cms.Getter configuration: any

    @cart.Getter getCurrentCartId: any
    @cart.Getter getCartItemsCount: any

    @layout.Mutation('TOGGLE_MOBILE_MENU') menuMobileShow: any
    @layout.Mutation('TOGGLE_ACCOUNT_MENU') menuAccountShow: any

    @wishlist.Getter getCurrentWishlistId: any
    @wishlist.Getter getWishlistItemsCount: any

    mapping = {
      ROWS: {
        component: 'one-row-footer',
      },
      COLS: {
        component: 'one-col-footer',
      },
    }

    availableComponents: any = {
      staticHtml: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
      list: () => import('~/components/molecules/lists/OneCmsGridList.vue'),
      collapse: () => import('~/components/molecules/lists/OneCmsGridCollapse.vue'),
      html: () => import('~/components/organisms/grid/shared/OneCmsStaticComponent.vue'),
      image: () => import('~/components/molecules/images/OneCmsGridImageComponent.vue'),
      link: () => import('~/components/molecules/links/OneCmsGridLinkComponent.vue'),
      codesScanner: () => import('~/components/organisms/shared/OneBarcodeScanner.vue'),
    }

    get isPlatformClosed(): boolean {
      return !this.$tenantConfig.isPlatformClosed || this.$auth.isAuthenticated
    }

    get isPlatformOpenCatalogAndAuth(): boolean {
      return this.$tenantConfig.isPurchaseEnabled || this.$auth.isAuthenticated
    }

    get mobileButtons() {
      return [
        {
          text: this.$t('footer.mobile.home') as string,
          icon: 'icon-home-2',
          click: this.onHomeClick,
        },
        {
          text: this.$t('footer.mobile.categories') as string,
          icon: 'icon-arrows-hamburger icon-lg icon-2x',
          click: this.onCategoriesClick,
        },
        {
          text: this.$t('footer.mobile.cart') as string,
          icon: 'icon-cart-empty',
          badge: this.isPlatformOpenCatalogAndAuth
            ? {
              count: this.itemsInCart,
            }
            : null,
          style: this.isPlatformOpenCatalogAndAuth
            ? null
            : {
              color: '#cecece',
            },
          click: this.onCartClick,
        },
        {
          text: this.$t('pages.wishlist') as string,
          icon: 'icon-basic-sheet-txt',
          click: this.onWishlistClick,
          badge: this.isPlatformOpenCatalogAndAuth
            ? {
              count: this.itemsInWishlist,
            }
            : null,
          style: this.isPlatformOpenCatalogAndAuth
            ? null
            : {
              color: '#cecece',
            },
        },
        {
          text: this.$auth.isAuthenticated
            ? (this.$t('footer.mobile.account') as string)
            : (this.$t('login_page.login_label') as string),
          icon: 'icon-user icon-lg icon-2x',
          click: this.$auth.isAuthenticated ? this.onAccountClick : this.onLoginRegisterClick,
        },
      ]
    }

    get footerSiteName() {
      return this.configuration.seo.title || (this.$t('default_seo.title') as string)
    }

    get footer(): any {
      return this.configuration && this.configuration.footer
    }

    get itemsInCart(): number {
      if (this.getCurrentCartId) {
        return this.getCartItemsCount(this.getCurrentCartId)
      }
      return 0
    }

    get itemsInWishlist(): number {
      return this.getCurrentWishlistId ? this.getWishlistItemsCount(this.getCurrentWishlistId) : 0
    }

    onHomeClick() {
      this.$router.push(this.localePath('index'))
    }

    onCartClick() {
      this.$router.push(this.localePath('cart'))
    }

    onLoginRegisterClick() {
      this.$router.push(this.localePath('login'))
    }

    onWishlistClick() {
      this.$router.push(this.localePath('wishlist'))
    }

    onAccountClick() {
      this.menuAccountShow(true)
    }

    onCategoriesClick() {
      this.menuMobileShow(true)
    }
  }
